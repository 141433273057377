<template>
    <div class="publish-device select-identity">
        <Header />
        <div class="w-1200">
            <div class="base-step">
                <div class="step-item" :class="{ 'active': true }"><span class="num">1</span><span class="name">注册账号</span></div>
                <i class="divider" :class="{ 'active': true }"></i>
                <div class="step-item" :class="{ 'active': true }"><span class="num">2</span><span class="name">选择身份</span></div>
                <i class="divider"></i>
                <div class="step-item"><span class="num">3</span><span class="name">申请账号身份</span></div>
                <i class="divider"></i>
                <div class="step-item"><span class="num">4</span><span class="name">后台审核</span></div>
            </div>
            <section class="form">
                <div class="form-title">选择身份</div>
                <div class="select-identity-list">
                    <div class="select-identity-item" :class="{ 'selected': isProject }" @click="isProject = true">
                        <div class="name">我是项目方</div>
                        <div class="content">成为项目方需要通过平台资质验证，<br />可以发布求租信息，可在平台进行预约机械设备。</div>
                    </div>
                    <div class="select-identity-item" :class="{ 'selected': !isProject }" @click="isProject = false">
                        <div class="name">我是供应商</div>
                        <div class="content">成为供应商选哟通过平台资质验证，与平台签订合同，<br />可发布设备租赁信息，由平台开具设备租赁发票。</div>
                    </div>
                </div>

            </section>
            <section class="form-btn-group">
                <!-- <span class="btn-item" @click="$router.push({name: 'projectApply'})">项目方下一步</span>
        <span class="btn-item" @click="$router.push({name: 'supplierApply'})">供应商下一步</span> -->
                <span class="btn-item" @click="next">下一步</span>
            </section>
        </div>
    </div>
</template>

<script>
import Header from "@/components/header"
export default {
    components: {
        Header
    },
    data() {
        return {
            isProject: true,
            input: ""
        };
    },
    mounted() {
        console.log("xxxxxxxxxxx", this.$route.params.mobile);
        if (!this.$route.params.mobile) {
            this.$router.push({ name: 'login', query: { title: '注册' } })
        }
    },
    methods: {
        next() {
            if (this.isProject) {
                this.$router.push({ name: 'projectApply', params: { mobile: this.$route.params.mobile, password: this.$route.params.password } })
            } else {
                this.$router.push({ name: 'supplierApply', params: { mobile: this.$route.params.mobile, password: this.$route.params.password } })
            }
        }
    }
}
</script>

<style scoped>

</style>
